import React, { useState, useEffect } from 'react';
import openSocket from 'socket.io-client';

import { makeStyles, } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { Formik, Field, Form } from 'formik';

import api from '../../services/api';
import { i18n } from '../../translate/i18n.js';
import toastError from '../../errors/toastError';
import {
	Button,
	Container,
	Paper,
	Select,
	TextField,
	Typography,
	Grid
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(8, 8, 3),
	},

	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
		marginBottom: 12,
	},
	paperbetween: {
		padding: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
		'justify-content': 'space-between',
		marginBottom: 12,
		width: '100%',
	},
	papercolumn: {
		padding: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
		marginBottom: 12,
		flexDirection: 'column',
	},
	form: {
		display: 'contents',
	},

	settingOption: {
		marginLeft: 'auto',
	},
	margin: {
		margin: theme.spacing(1),
	},
}));

const Settings = () => {
	const classes = useStyles();

	const [settings, setSettings] = useState([]);
	const [settingAlerts, setSettingsAlerts] = useState({
		AlertDateFM: '',
		alertMaxTime: '',
		alert_only_loggin: 'false',
	});
	const [settingTraccar, setSettingTraccar] = useState({
		TC_SERVER: '',
		notification_number: '',
	});
	const [settingBot, setSettingBot] = useState({
		is_transfer: 'false',
		skip_send_contact: 'false',
		suport_number: '',
		finace_number: '',
	});

	useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get('/settings');
				setSettings(data);
				setSettingsAlerts({
					AlertDateFM: data.find((s) => s.key === 'AlertDateFM')?.value || '',
					alertMaxTime: data.find((s) => s.key === 'alertMaxTime')?.value || '',
					alert_only_loggin: data.find((s) => s.key === 'alert_only_loggin')?.value || 'false',
				});
				setSettingTraccar({
					TC_SERVER: data.find((s) => s.key === 'TC_SERVER')?.value || '',
					notification_number: data.find((s) => s.key === 'notification_number')?.value || '',
				});
				setSettingBot({
					is_transfer: data.find((s) => s.key === 'is_transfer')?.value || '',
					skip_send_contact: data.find((s) => s.key === 'skip_send_contact')?.value || '',
					suport_number: data.find((s) => s.key === 'suport_number')?.value || '',
					finace_number: data.find((s) => s.key === 'finace_number')?.value || '',
				});
			} catch (err) {
				console.log('Error no  ', err);
				toastError(err);
			}
		};
		fetchSession();
	}, []);

	useEffect(() => {
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

		socket.on('settings', (data) => {
			if (data.action === 'update') {
				setSettings((prevState) => {
					const aux = [...prevState];
					const settingIndex = aux.findIndex((s) => s.key === data.setting.key);
					aux[settingIndex].value = data.setting.value;
					return aux;
				});
				switch (data.setting.key) {
					case "AlertDateFM":
						setSettingsAlerts((prevState) => {
							prevState.AlertDateFM = data.setting.value;
							return prevState;
						}
						);
						break;
					case "alertMaxTime":
						setSettingsAlerts((prevState) => {
							prevState.alertMaxTime = data.setting.value;
							return prevState;
						}
						);
						break;
					case "alert_only_loggin":
						setSettingsAlerts((prevState) => {
							prevState.alert_only_loggin = data.setting.value;
							return prevState;
						}
						);
						break;
					case "TC_SERVER":
						setSettingTraccar((prevState) => {
							prevState.TC_SERVER = data.setting.value;
							return prevState;
						}
						);
						break;
					case "notification_number":
						setSettingTraccar((prevState) => {
							prevState.notification_number = data.setting.value;
							return prevState;
						}
						);
						break;
					case "is_transfer":
						setSettingBot((prevState) => {
							prevState.is_transfer = data.setting.value;
							return prevState;
						}
						);
						break;
					case "skip_send_contact":
						setSettingBot((prevState) => {
							prevState.skip_send_contact = data.setting.value;
							return prevState;
						}
						);
						break;
					case "suport_number":
						setSettingBot((prevState) => {
							prevState.suport_number = data.setting.value;
							return prevState;
						}
						);
						break;
					case "finace_number":
						setSettingBot((prevState) => {
							prevState.finace_number = data.setting.value;
							return prevState;
						}
						);
						break;
					default:
						console.log('No se encontro el setting');
						break;
				}
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	const handleChangeSetting = async (e) => {
		const selectedValue = e.target.value;
		const settingKey = e.target.name;

		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});

			toast.success(i18n.t('settings.success'));
		} catch (err) {
			toastError(err);
		}
	};

	const getSettingValue = (key) => {
		const { value } = settings.find((s) => s.key === key);
		return value;
	};

	return (
		<div className={classes.root}>
			<Container className={classes.container} maxWidth='sm'>
				<Typography variant='body2' gutterBottom>
					{i18n.t('settings.title')}
				</Typography>
				<Paper className={classes.paper}>

					<Typography variant='body1'>
						{i18n.t('settings.settings.userCreation.name')}
					</Typography>
					<Select
						margin='dense'
						variant='outlined'
						native
						id='userCreation-setting'
						name='userCreation'
						value={
							settings && settings.length > 0 && getSettingValue('userCreation')
						}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					>
						<option value='enabled'>
							{i18n.t('settings.settings.userCreation.options.enabled')}
						</option>
						<option value='disabled'>
							{i18n.t('settings.settings.userCreation.options.disabled')}
						</option>
					</Select>

				</Paper>

				<Paper className={classes.paper}>
					<TextField
						id='api-token-setting'
						readonly
						label='Token Api'
						margin='dense'
						variant='outlined'
						fullWidth
						value={
							settings && settings.length > 0 && getSettingValue('userApiToken')
						}
					/>
				</Paper>
				<Paper className={classes.papercolumn}>
					<Typography variant='h6' gutterBottom>
						BOT configurações
					</Typography>
					<Formik
						enableReinitialize={true}
						initialValues={settingBot}
						onSubmit={async (values, { setSubmitting }) => {
							try {
								for (const [key, value] of Object.entries(values)) {
									await api.put(`/settings/${key}`, {
										value,
									});
								}
								toast.success(i18n.t('settings.success'));
							} catch (err) {
								toastError(err);
							}

							setSubmitting(false);
						}}
					>
						<Form className={classes.form}>

							<Grid className={classes.paperbetween}>
								<Typography variant='body1'>
									Encaminhar suporte para números
								</Typography>
								<Field
									as={Select}
									name='is_transfer'
									type='text'
									label='Enviar contato quando usuário chamar suporte.'
									margin='dense'
									variant='outlined'

								>
									<option value='true'>
										{i18n.t('settings.settings.userCreation.options.enabled')}
									</option>
									<option value='false'>
										{i18n.t('settings.settings.userCreation.options.disabled')}
									</option>
								</Field>
							</Grid>
							<Field
								as={TextField}
								name='suport_number'
								type='text'
								label='Número do suporte'
								margin='dense'
								variant='outlined'
								fullWidth
							/>
							<Field
								as={TextField}
								name='finace_number'
								type='text'
								label='Número financeiro'
								margin='dense'
								variant='outlined'
								fullWidth
							/>
							<Button
								type='submit'
								color='primary'
								//	disabled={isSubmitting}
								variant='contained'
								className={classes.btnWrapper}
							>
								Salvar
							</Button>
						</Form>
					</Formik>
				</Paper>

				<Paper className={classes.papercolumn}>
					<Typography variant='h6' gutterBottom>
						Traccar configurações
					</Typography>
					<Formik
						enableReinitialize={true}
						initialValues={settingTraccar}
						onSubmit={async (values, { setSubmitting }) => {
							try {
								for (const [key, value] of Object.entries(values)) {
									await api.put(`/settings/${key}`, {
										value,
									});
								}
								toast.success(i18n.t('settings.success'));
							} catch (err) {
								toastError(err);
							}

							setSubmitting(false);
						}}
					>
						<Form className={classes.form}>
							<Field
								as={TextField}
								name='TC_SERVER'
								type='text'
								label='URL do servidor Traccar'
								margin='dense'
								variant='outlined'
								fullWidth
							/>
							<Field
								as={TextField}
								name='notification_number'
								type='text'
								label='Número de notificações de falha'
								margin='dense'
								variant='outlined'
								fullWidth
							/>
							<Button
								type='submit'
								color='primary'
								//	disabled={isSubmitting}
								variant='contained'
								className={classes.btnWrapper}
							>
								Salvar
							</Button>
						</Form>
					</Formik>
				</Paper>

				<Paper className={classes.papercolumn}>
					<Typography variant='h6' gutterBottom>
						Alertas
					</Typography>
					<Formik
						enableReinitialize={true}
						initialValues={settingAlerts}
						onSubmit={async (values, { setSubmitting }) => {

							try {
								for (const [key, value] of Object.entries(values)) {
									await api.put(`/settings/${key}`, {
										value,
									});
								}
								toast.success(i18n.t('settings.success'));
							} catch (err) {
								toastError(err);
							}

							setSubmitting(false);
						}}
					>
						<Form className={classes.form}>

							<Grid className={classes.paperbetween}>
								<Typography variant='body1'>
									Enviar alertas apenas para úsuarios logado
								</Typography>
								<Field
									as={Select}
									name='alert_only_loggin'
									type='text'
									label='Enviar contato quando usuário chamar suporte.'
									margin='dense'
									variant='outlined'

								>
									<option value='true'>
										{i18n.t('settings.settings.userCreation.options.enabled')}
									</option>
									<option value='false'>
										{i18n.t('settings.settings.userCreation.options.disabled')}
									</option>
								</Field>
							</Grid>
							<Field
								as={TextField}
								name='AlertDateFM'
								type='text'
								label='Formato da data'
								margin='dense'
								variant='outlined'
								fullWidth
							/>
							<Field
								as={TextField}
								name='alertMaxTime'
								type='number'
								label='Tempo máximo de atraso alerta'
								margin='dense'
								variant='outlined'
								fullWidth
							/>
							<Button
								type='submit'
								color='primary'
								//	disabled={isSubmitting}
								variant='contained'
								className={classes.btnWrapper}
							>
								Salvar
							</Button>
						</Form>
					</Formik>
				</Paper>
			</Container>
		</div>
	);
};

export default Settings;
